<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="2" disabledSignee="false" :disabledSignatory="disabledSignatory" :disabledReview="disabledReview"/>

    <div v-if="objLSProj.status !== 'WIP'" class="generalBox border shadow-sm">
        <div class="row">
            <div class="col-5 col-lg-4 mb-2 text-center">
                <fa icon="triangle-exclamation" style="font-size: 120px"/>
            </div>
            <div class="col-7 col-lg-8 mb-2">
                <div class="fw-bold mb-3" style="font-size: 20px">Hmmm... We're sorry, but you don't have permission to view this page.</div>
                <div>Please email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a> for more information.</div>
                <div>Or you may search <span class="text-primary isLink" @click="$router.push('/project')">folder</span> from the rack again.</div>
            </div>
            <div class="col-12"><hr class="my-3"/></div>
            <div class="col-12 text-center">
                <button class="btn btn-secondary" @click="$router.push({ path: '/project' })">
                    <fa icon="folder" size="lg" class="me-2" />Folder
                </button>
            </div>
        </div>
    </div>

    <div v-if="objLSProj.status === 'WIP'" class="generalBox border shadow-sm">
        <div v-if="project && project.folderName" class="row">
            <div class="col-12">
                <ProjTitle :projId="project.folderId" :isFavourite="project.isFavourite" :mode="project.mode" :permission="project.permission" :projTitle="project.folderName" :totalSignee="project.jSignee.length" :totalDoc="project.totalDocument" :status="project.status" :shred="project.shred" :privacy="project.privacy" :isEdit="true" @refreshProj="getProject" :key="randKey"/>
            </div>

            <div class="col-12"><hr class="mb-2 mt-3 py-0" /></div>

            <div class="col-md-9 mb-3">
                <span>You may include all the signees (participants) in the folder</span>
            </div>
            <div class="col-md-3 text-end pe-3 mb-3">
                <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#mdlAddSignee" @click="resetSignee" class="text-primary isLink"> -->
                <a href="#" data-bs-toggle="modal" data-bs-target="#mdlAddSignee" class="text-primary isLink">
                    <fa icon="plus" class="me-2"/>Add Signee
                </a>
            </div>

            <div class="col-12">
                <table class="table table-hover">
                    <tbody>
                        <tr v-for="(s, sIdx) in signees" :key="s.signeeId">
                            <td class="align-middle">
                                <div class="row">
                                    <!-- <div class="text-danger small">signeeName: {{s.signeeName}}, full:{{s.fullName}}, displayname: {{s.displayName}}</div> -->
                                    <!-- <div class="text-danger small">{{s}}</div> -->
                                    <div class="col-12 col-lg-8 align-text-top">
                                        <table>
                                            <tr>
                                                <td class="pe-2">
                                                    <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" :key="s.signeeId + s.privilege + sIdx" width="35" height="35" />
                                                    <!-- <Avatar :isUser="s.isUser" :avatar="s.jAvatar.imgBase64" displayName="" :isVerified="s.isVerified" :isActive="s.status" :key="s.signeeId + s.privilege + sIdx" :privilege="s.privilege" class="fw-bold" width="35" height="35" /> -->
                                                </td>
                                                <td class="ms-1 align-middle">
                                                    <div>{{s.signeeName}}</div>
                                                    <div class="lblTiny text-grey">{{s.signeeId}}</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <table width="100%" border="0">
                                            <tr>
                                                <td><span class="badge bg-grey">{{s.role}}</span></td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart">
                                                        <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                                            <fa icon="ellipsis-h" class="text-secondary"/>
                                                        </a>
                                                        <ul class="dropdown-menu dropdown-menu-secondary">
                                                            <li><a class="dropdown-item" href="#" @click="modifySignee(s, index)" data-bs-toggle="modal" data-bs-target="#mdlAddSignee">Modify</a></li>
                                                            <!-- <li><a class="dropdown-item" href="#">Add private attachments</a></li> -->
                                                            <li><a class="dropdown-item" href="#" @click="delSignee(s, s.signeeId)">Remove</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12 mb-3">
                All signees will receive an email invitation to sign/view the documents, and the signing process is not reversible. <br>The final signed copy will be distributed to all the signees and archived in our secure file vault.
            </div>

            <div class="col-12"></div>

        </div>
        <div v-else class="row">
            <div class="col-12">
                <fa icon="spinner" size="lg" class="me-2" />Loading...
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr class="my-4"/>
            </div>
            <div class="col-6 mb-1">
                <button class="btn btn-outline-secondary" @click="$router.push({ path: '/uploadDoc/' + objLSProj.id })">
                    <fa icon="circle-arrow-left" size="lg" class="me-2" />Back
                </button> 
            </div>
            <div class="col-6 text-end mb-1">
                <button class="btn btn-secondary" @click="$router.push({ path: '/signatory' })" :class="disabledSignatory === true ? 'disabled' : ''">
                    <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                </button> 
            </div>
        </div>
    </div>

    <!-- Modal: Add signee --> 
    <div
        ref="RefModalSample"
        class="modal fade"
        id="mdlAddSignee"
        tabindex="-1"
        aria-labelledby="AddSignee"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" :class="signeeId === null ? 'modal-lg' : ''">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="signeeId === null" class="modal-title" id="exampleModalLabel"> Add Signee</h5>
                    <h5 v-else class="modal-title" id="exampleModalLabel"> Modify Signee</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div :class="signeeId === null ? 'col-12 col-lg-7' : 'col-12'">
                            <div class="row">
                                <input type="hidden" class="form-control" v-model="signeeId" >
                                <div class="col-12 mb-3">
                                    <input type="email" class="form-control" v-model="signeeEmail" @focusout="getSigneeDisplayName()" @keyup.enter="getSigneeDisplayName()" placeholder="Email *" :disabled="signeeId !== null">
                                    <div v-if="AddSigneeErr === true" class="text-danger small">Compulsory</div>
                                </div>

                                <div class="col-12 mb-3">
                                    <input type="text" class="form-control" v-model="signeeName" placeholder="Name *">
                                </div>

                                <div class="col-12 mb-3">
                                    <label class="form-label text-secondary">Role (E.g. BUYERWITNESS, SELLERWITNESS)</label>
                                    <div v-if="roleList.length === 0" class="custom-dropdown" @click="setRoleFocus">
                                        <Tag :tags="roleList" @getTags="getTags" />
                                        <input type="text" v-model="role" ref="roleRef" class="role" autocomplete="off" maxlength="10" 
                                            @input="roleChange" @keyup.enter="enterRole()" @focusout="enterRole()" autofocus style="text-transform: uppercase" />
                                        <ul class="dropdown-menu dropdown-menu-secondary" ref="roleOptRef">
                                            <li class="dropdown-item" v-for="(role, idx) in roleOption" :key="role">
                                                <span @click="selectRole(role)" class="pe-3 text-decoration-none" style="cursor: pointer">{{role}}</span>
                                                <fa icon="trash" class="float-end isLink text-white pt-1" @click="delRoleOption(role, idx)"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="custom-dropdown" >
                                        <Tag :tags="roleList" @getTags="getTags" @showRoleInput="role = null"/>
                                    </div>
                                </div>

                                <div v-if="signeePrivilege !== 'OWNER'" class="col-12 mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" v-model="signeeIsEditor" value="true" >
                                        <label class="form-check-label" for="mySwitch">Editor</label>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <!-- Signee list -->
                        <div v-if="signeeId === null" class="col-12 col-lg-5">
                            <div class="row">

                                <div class="col-12 mb-2">
                                    <div class="generalContentBox border shadow-sm greyBox">
                                        <div class="mb-2 p-0">
                                            <table width="100%">
                                                <tr>
                                                    <td><span class="filter-item" :class="filSig === 'recent' ? 'active' : ''" @click="setFilter('recent')">Recent</span></td>
                                                    <td><span class="filter-item" :class="filSig === 'freq' ? 'active' : ''"  @click="setFilter('freq')">Frequent</span></td>
                                                    <!-- enerson request hide tmp <td><span class="filter-item" :class="filSig === 'contact' ? 'active' : ''"  @click="setFilter('contact')">Contact</span></td> -->
                                                </tr>
                                                <tr>
                                                    <td colspan="3">
                                                        <input type="text" class="form-control mt-2" v-model="filterName" @dblclick="filterName= ''" placeholder="Name / Email / Tag" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div>
                                            <table class="table table-sm table-hover">
                                                <tbody>
                                                    <tr v-for="r in arrList" :key="r">
                                                        <td class="isLink" @click="selectSignee(r)">
                                                            <div>{{ r.signeeName === undefined ? '-' : r.signeeName }}</div>
                                                            <div class="lblTiny">{{r.userId}}</div>
                                                            <div>
                                                                <span class="badge tag isLabel" v-for="t in r.jTag" :key="t">
                                                                    <fa icon="hashtag" class="pe-1"/>{{ t }}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="signeeId === null" type="button" @click="addSignee" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" size="lg" class="me-2" />Save
                    </button>
                    <button v-else type="button" @click="updSignee" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" size="lg" class="me-2" />Update
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End --> 

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import { ref, inject, onMounted, watch } from 'vue'
import { useStore } from '@/stores/store'
import { useRouter, useRoute } from 'vue-router'
import draggable from 'vuedraggable'
import Alert from '@/components/Alert.vue'
import Tag from '@/components/Tag.vue'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import funcs from '@/functions/function'
import ProjTitle from '@/components/ProjTitle.vue'


export default {
    name: 'AddSignee',
    components: {
        TopNavigation, draggable, Alert, Tag, /* Avatar, */ ImgAvatar, ProjTitle
    },
    setup() {
        const alert = ref([])
        const axios = inject('axios')
        const func = funcs
        const route = useRoute()
        const router = useRouter()
        const { getUserId, getDisplayName } = useStore()
        const store = useStore()
        
        const inSequence = ref(false)
        const dragging = ref(false)
        const signees = ref([])
        const signeeEmail = ref(null)
        const signeeName = ref(null)
        const signeeRole = ref(null)
        const signeePrivilege = ref(null)
        const signeeIsEditor = ref(false)
        const signeeId = ref(null)
        const signeeIndex = ref(null) // edit signee
        const totalSignee = ref(0)
        const totalDoc = ref(0)
        const project = ref([])
        const classAll = ref('btn btn-sm btn-secondary active')
        const classRestrict = ref('btn btn-sm btn-secondary')
        const isMeSignee = ref(false)
        const AddSigneeErr = ref(false)

        const role = ref(null)
        const rolePrev = ref(null)
        const roleRef = ref(null)
        const roleOptRef = ref(null)
        const roleList = ref([])
        const roleOption = ref([])

        const arrList = ref([])
        const filSig = ref('recent') // recent/freq/contact
        const filterName = ref('')

        const disabledSignatory = ref(true)
        const disabledReview = ref(true)

        const permToAccessProject = ref(true)
        const objLSProj = ref({})
        const randKey = ref(1)


        watch([filterName], () => {
            setFilter(filSig.value)

        })

        const getSigneeDisplayName = () => {
            if (signeeName.value === null || signeeName.value === '' ) {
                const p = {
                    userId: signeeEmail.value,
                }

                axios.post('/user/member/user', p)
                    .then((res) => {
                        if (res.data.status === 1) {
                            signeeName.value = res.data.data.displayName
                        } 
                        
                    })
                    .catch((error) => {
                        console.info('- - - getSigneeDisplayName: Err', error)
                        func.addLog('addSignee', 'getSigneeDisplayName - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                    })
            }
        }

        const getSignees = () => {
            isMeSignee.value = false
            axios.get( '/signon/' + objLSProj.value.id + '/signee/list')
                .then((res) => {

                    if (res.data.status === 1) {
                        signees.value = res.data.data
                        totalSignee.value = res.data.description.rowCount

                        if (signees.value !== []) {
                            signees.value.forEach((item, idx) => {
                                if (item.signeeId === getUserId.value) {
                                    isMeSignee.value = true
                                }
                            })
                        }
                        setTimeout(() => setPermission(), 1000)

                    } else {
                        func.addLog('addSignee', 'getSignees', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'getSignees - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const setPermission = () => {
            if (totalSignee.value === 0 || totalDoc.value === 0) {
                disabledSignatory.value = true
                disabledReview.value = true

            } else {
                disabledSignatory.value = false
                disabledReview.value = false

            }
        }

        const addSignee = async () => {
            
            const p = {
                email: signeeEmail.value, 
                signeeName: signeeName.value === null || signeeName.value === '' ? signeeEmail.value : signeeName.value,
                role: roleList.value[0] === undefined ? '' : roleList.value[0]
            }   

            if (signeeIsEditor.value === true) {
                p.privilege = 'EDITOR'

            } else {
                p.privilege = 'SIGNEE'

            }

            if (signeeEmail.value === null || signeeEmail.value === '') {
                AddSigneeErr.value = true

                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Signee email is compulsory.'
                })

            } else {
                AddSigneeErr.value = false
                axios.post( '/signon/' + objLSProj.value.id + '/signee', p
                ).then((res) => {

                    if (res.data.status === 1) {
                        getSignees()    
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signee is added."
                        })

                    } else {
                        func.addLog('addSignee', 'addSignee', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'duplicate_signee') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'The signee already exists.'
                                })
                            
                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'addSignee - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

                resetSignee()
            }
        }

        const modifySignee = (signee, index) => {
            AddSigneeErr.value = false

            signeeIndex.value = index
            signeeName.value = signee.signeeName
            signeeEmail.value = signee.signeeId
            signeeId.value = signee.signeeId
            signeePrivilege.value = signee.privilege

            if (signeePrivilege.value === 'EDITOR') {
                signeeIsEditor.value = true

            } else {
                signeeIsEditor.value = false

            }

            roleList.value = []
            if (signee.role !== '') {
                roleList.value.push(signee.role)

            }
        }

        const updSignee = () => {
            AddSigneeErr.value = false

            const p = {
                signeeName: signeeName.value === null || signeeName.value === '' ? signeeEmail.value : signeeName.value,
                role: roleList.value[0] === undefined ? '' : roleList.value[0]
            }

            if (signeePrivilege.value !== 'OWNER') {
                if (signeeIsEditor.value === true) {
                    p.privilege = 'EDITOR'

                } else {
                    p.privilege = 'SIGNEE'

                }
            }
            axios.put( '/signon/' + objLSProj.value.id + '/signee/' + signeeId.value, p)
                .then((res) => {

                    if (res.data.status === 1) {
                        resetSignee()
                        getSignees() 

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signee is updated."
                        })

                    } else {
                        func.addLog('addSignee', 'updSignee', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'updSignee - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const delSignee = (signee, id) => {
            AddSigneeErr.value = false
            
            axios.delete( '/signon/' + objLSProj.value.id + '/signee/' + id)
                .then((res) => {

                    if (res.data.status === 1) {
                        getSignees()    
                        resetSignee()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signee is removed."
                        })

                    } else {
                        func.addLog('addSignee', 'delSignee', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'no_permission' && res.data.description === 'owner cannot be deleted') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'You are not allow to delete folder owner.'
                                })

                            } else if (res.data.message === 'no_permission' && res.data.description === '') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'You are not allow to delete participating signee.'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'delSignee - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const resetSignee = () => {
            signeeName.value = null
            signeeEmail.value = null
            signeeRole.value = null
            signeePrivilege.value = null
            signeeIsEditor.value = false
            signeeId.value = null
            roleList.value = []         

        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const selectRole = (val) => {
            roleList.value.push(val)
            role.value = null
            signeeRole.value = val

        }

        const getTags = (tags) => {
            roleList.value = tags

        }

        const roleChange = () => {
            if (!roleOptRef.value.classList.value.includes('show')) {
                roleOptRef.value.classList.add('show')
            }

        }

        const enterRole = () => {
            if (role.value !== null && role.value !== '' && role.value.length > 0) {
                console.info('rolePrev: ' + rolePrev.value, typeof rolePrev.value, 'role: ' + role.value.toUpperCase(), typeof role.value.toUpperCase())

                if (roleOption.value.includes(role.value.toUpperCase())) {
                // if (role.value.toUpperCase() === rolePrev.value) {
                    roleList.value = []
                    roleList.value.push(role.value.toUpperCase())
                } else {

                    const p = {
                        signeeRole: role.value.toUpperCase()
                    }

                    axios.post( '/signon/signeeRole', p)
                        .then((res) => {

                            if (res.data.status === 1) {
                                roleOption.value.push(role.value.toUpperCase())
                                roleList.value.push(role.value.toUpperCase())
                                rolePrev.value = role.value.toUpperCase()

                                setTimeout(() => {
                                    role.value = null
                                }, 500)

                            } else {
                                func.addLog('addSignee', 'enterRole', res)

                                if (res && res.data !== null && res.data !== undefined) {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })

                                }
                            }
                        })
                        .catch((error) => {
                            func.addLog('addSignee', 'enterRole - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                            
                        })
                    }
                }
        }

        const setRoleFocus = () => {
            if (roleList.value.length === 0 ) {
                roleRef.value.focus()

                if (roleOptRef.value.classList.value.includes('show')) {
                    roleOptRef.value.classList.remove('show')

                } else {
                    roleOptRef.value.classList.add('show')

                }
            }
        }

        const getRoleList = () => {
            axios.get( '/signon/signeeRole/list')
                .then((res) => {

                    if (res.data.status === 1) {
                       roleOption.value = res.data.data

                    } else {
                        func.addLog('addSignee', 'getRoleList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'getRoleList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const delRoleOption = (val, idx) => {
            axios.delete( '/signon/signeeRole/' + val)
                .then((res) => {

                    if (res.data.status === 1) {
                        roleOption.value.splice(idx, 1)

                    } else {
                        func.addLog('addSignee', 'delRoleOption', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('addSignee', 'delRoleOption - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const setFilter = (opt) => {
            filSig.value = opt

            if (opt === 'freq') {
                const p = {
                    type: 'FREQUENT',
                    name: filterName.value,
                    limit: "0, 5"
                }

                axios.post( '/signon/proposedSigneeList', p)
                    .then((res) => {
                        if (res.data.status === 1) {
                            arrList.value = res.data.data

                        } else {
                            func.addLog('addSignee', 'setFilter Frequent', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('addSignee', 'setFilter Frequent - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })

            } else if (opt === 'contact') {

                arrList.value = [
                    { userId: 'alex.hang@yahoo.com', name: 'Hang Kar Meng', tags: [] },
                    { userId: 'joey1980@gmail.com', name: 'Joey80', tags: ['mcis', 'management'] },
                ]

            } else {
                const p = {
                    type: 'RECENT',
                    name: filterName.value,
                    limit: "0, 5"
                }

                axios.post( '/signon/proposedSigneeList', p)
                    .then((res) => {
                        if (res.data.status === 1) {
                            arrList.value = res.data.data

                        } else {
                            func.addLog('addSignee', 'setFilter Recent', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('addSignee', 'setFilter Recent - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }
        }

        const selectSignee = (s) => {
            signeeEmail.value = s.userId
            signeeName.value = s.signeeName
        }

        const getProject = (id) => {
            axios.get( '/signon/' + id )
                .then((res) => {

                    if (res.data.status === 1) {
                        project.value = res.data.data
                        totalDoc.value = res.data.data.totalDocument
                        // console.info('getproj', JSON.stringify(project.value))
                        randKey.value = randKey.value + 1

                        if (res.data.data.status === 'WIP') {
                            permToAccessProject.value = true

                        } else {
                            permToAccessProject.value = false

                        }

                        // Update latest project local storage
                        let objLSProj = { id: res.data.data.folderId, name: res.data.data.folderName, status: res.data.data.status}
                        localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj))

                    } else {
                        func.addLog('addSignee', 'getProject', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    permToAccessProject.value = false
                    func.addLog('addSignee', 'getProject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        
        onMounted(() => {
            if (func.isEmptyObject(localStorage.getItem('project')) === false) {
                objLSProj.value = func.decodeStrToJsonSimple(localStorage.getItem('project'))
            }

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    // console.info('detect query l', route.query.l)
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            let objMdl1 = document.querySelector('#mdlAddSignee')
            objMdl1.addEventListener("show.bs.modal", function (event) {
                if (AddSigneeErr.value === false || signeeIndex.value !== null) {
                    resetSignee()
                }
            })

            getSignees()
            setFilter('recent')
            getRoleList()

            if (objLSProj.value.id) {
                getProject(objLSProj.value.id)
                
            }
        }) 

        return { 
            alert, closeAlert, inSequence, dragging, signees, store, router, axios, 
            delSignee, addSignee, modifySignee, updSignee, resetSignee, getSignees, 
            signeeEmail, signeeName, signeeRole, signeePrivilege, signeeIsEditor, signeeId, signeeIndex,
            getUserId, totalSignee, project, classAll, classRestrict, 
            role, rolePrev, roleRef, roleOption, roleOptRef, roleList, selectRole, getTags, roleChange, enterRole, setRoleFocus, delRoleOption,
            disabledSignatory, disabledReview, isMeSignee, func,
            arrList, filSig, setFilter, selectSignee, filterName, randKey, getProject, permToAccessProject, objLSProj, AddSigneeErr, totalDoc,
            getSigneeDisplayName
        }
    }
    
}
</script>
// https://www.npmjs.com/package/vue3-form-render-vuedraggable
// npm i -S vuedraggable@next

<style>
.button {
  margin-top: 35px;
}
.handle {
  cursor: move;
}
.close {
  cursor: pointer;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}

.custom-dropdown {
    text-align: left;
    display: block;
    width: 100%;
    padding: 4px 5px;
    font-size: 1rem;
    font-weight: 400;
    height: 40px;
    color: #212529;
    background-color: var(--bgcolor-body-color);
    background-clip: padding-box;
    border: 1px solid var(--accent-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.role {
    width: 80px;
    height: 30px;
    font-size: 12px;
    color: var(--form-control-text);
    background-color: var(--bgcolor-body-color);
    border: 1px solid var(--bgcolor-body-color);
}

.role:focus { 
    outline: none; 
    color: var(--form-control-text);
    background-color: var(--bgcolor-body-color);
}

.circle
{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid;
  background-image:
    linear-gradient(18deg, white 50%, transparent 50%),
    linear-gradient(-90deg, red 50%, transparent 50%);
}

.greyBox {
  background-color: var(--auth-box-bg);
}

.filter-item {
    background: none;
    margin: 5px 5px;
    padding: 5px;
}

.filter-item.active {
  color: var(--bs-primary);
  font-weight: bold;
  border-bottom: 2px solid var(--bs-primary);
}

.filter-item.active:hover {
  color: var(--bs-primary);
  font-weight: bold;
  border-bottom: 2px solid var(--bs-primary);
  cursor: text;
}

.filter-item:hover {
  color: var(--bs-primary);
  cursor: pointer;
} 
</style>